body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unstyled-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
}

#hhg-sidis-builder {
  > div {
    position: fixed;
    top: 68px;
    right: 0;
    bottom: 0;
    left: 240px;
    overflow-x: overlay;

    & > div:first-child > div:first-child {
      padding-bottom: 100px;
    }

    .layout-sider-hidden & {
      left: 80px;
      transition: 0.3s ease left;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 6px;
    }
  }

  .ant-tabs-tabpane {
    height: 100%;
    max-height: 100%;
  }

  .template-float-actions {
    position: fixed;
    left: 260px;
    bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;
    z-index: 2;

    .ant-btn-lg {
      width: 32px;
      height: 32px;
      min-width: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .layout-sider-hidden & {
      left: 100px;
    }
  }
}

.campaign-step-settings {
  .ant-popover-inner {
    border-radius: 12px;
  }
  .ant-popover-inner-content {
    padding: 8px;
  }
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.highcharts-credits {
  display: none !important;
}

.highcharts-exporting-group {
  display: none !important;
}

.bg-gradient {
  background-image: linear-gradient(269.36deg, #5FDD8B 17.79%, #2B94FF 84.02%);

  &[data-disabled="true"] {
    background-image: linear-gradient(269.36deg, #d9d9d9 17.79%, #d9d9d9 84.02%);
  }
}