@tailwind base;

@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #8c8c8c;
    border-radius: 2px;
    outline: none;
  }
}
